import { useIntl } from "react-intl";
import translations from "../../translations/constants";
import { Button } from "../Button";
import { Header } from "../Header";

type Props = {
    mode: "route" | "other";
}

export const ContactCard = ({ mode }: Props) => {
    const intl = useIntl();

    return <div className={`${mode === "route" ? "" : "p-16 bg-white rounded-lg dark:bg-gray-800"}`}>
        {mode === "other" && <Header level={2} className="mb-8 text-center">Lassen Sie uns über Ihre Veranstaltung reden!</Header>}

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <a href="mailto:finn@tegeler.events" className="w-full">
                <Button type="default" color="primary" fullWidth className="h-full">
                    {intl.formatMessage(translations.components.contact.emailButton)}
                </Button>
            </a>

            <a href="https://files.finn-tegeler.de/apps/forms/s/neomg3y3poTtjPmEF8o5iXZy" target="_blank" rel="noreferrer" className="w-full">
                <Button type="outline" color="primary" fullWidth className="h-full">
                    {intl.formatMessage(translations.components.contact.contactFormButton)}
                </Button>
            </a>

            <a href="tel:+4924195874" className="w-full">
                <Button type="outline" color="secondary" fullWidth className="h-full">
                    {intl.formatMessage(translations.components.contact.callButton)}
                </Button>
            </a>
        </div>
    </div>
}