import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "../App";
import { ContactRoute } from "./contact";
import { FourZeroFourRoute } from "./error/404";
import { HomeRoute } from "./home";
import { ProjectsRoute } from "./project";
import { ProjectRoute } from "./project/[slug]";
import { ServicesRoute } from "./services";
import { ServiceRoute } from "./services/[slug]";

export const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:lang?/" element={<App />}>
                    <Route path="" element={<HomeRoute />} />
                    <Route path="services" element={<ServicesRoute />} />
                    <Route path="services/:slug" element={<ServiceRoute />} />
                    <Route path="projekt" element={<ProjectsRoute />} />
                    <Route path="projekt/mit/:groupSlug" element={<ProjectsRoute />} />
                    <Route path="projekt/:slug" element={<ProjectRoute />} />
                    <Route path="contact" element={<ContactRoute />} />
                    <Route element={<FourZeroFourRoute />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
