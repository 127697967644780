import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import strapi from "../../../api";
import { Button } from "../../../components/Button";
import { ContactCard } from "../../../components/ContactCard";
import { Link } from "../../../components/Link";
import { ProjectCarousel } from "../../../components/ProjectCarousel";
import { ProjectGroupTags } from "../../../components/ProjectGroupTags";
import { useImage } from "../../../hooks/useImage";
import { useLanguage } from "../../../hooks/useLanguage";
import { ProjectGroups, Projects } from "../../../models/types";
import translations from "../../../translations/constants";

export const ProjectRoute = () => {
    const intl = useIntl();
    const { getImageUrl } = useImage();
    const [language] = useLanguage();
    const { slug } = useParams();

    const oneProjectQuery = useQuery({
        queryKey: [language, "project", slug],
        queryFn: async () => {
            const projects = await strapi.find<Projects[]>("projects", {
                slug,
                _locale: language,
            })

            if (projects.length !== 1) return undefined;

            return projects[0];
        },
    });

    if (oneProjectQuery.isLoading)
        return (
            <div className="flex flex-col justify-center items-center gap-4 h-full">

                <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-primary-default" />

                <h1 className="text-2xl font-semibold tracking-wide text-gray-800 dark:text-white lg:text-3xl mb-4">
                    {intl.formatMessage(translations.components.projects.loadingTitle)}
                </h1>
            </div>
        );

    if (oneProjectQuery.isError || (oneProjectQuery.isSuccess && oneProjectQuery.data === undefined))
        return (
            <div className="flex flex-col justify-center items-center gap-4 h-full">
                <h1 className="text-2xl font-semibold tracking-wide text-gray-800 dark:text-white lg:text-3xl mb-4">
                    {intl.formatMessage(translations.components.projects.projectDoesNotExistTitle)}
                </h1>

                <Link to="/projekt">
                    <Button color="primary" type="default">
                        {intl.formatMessage(translations.components.projects.backButton)}
                    </Button>
                </Link>
            </div>
        );

    return (
        <>
            <Helmet>
                <title>{intl.formatMessage(translations.components.projects.projectMetaTitle, {
                    projectTitle: oneProjectQuery.data?.title
                })}</title>
            </Helmet>

            <div className="container lg:pt-4 mx-auto">
                <Link to="/projekt">
                    <button className="mb-4 lg:mb-8 flex items-center justify-center below_lg:w-full  px-5 py-2 text-lg text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="w-6 h-6 rtl:rotate-180">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                        </svg>

                        <span>{intl.formatMessage(translations.components.projects.backButton)}</span>
                    </button>
                </Link>

                <div className="below_lg:p-4 below_lg:bg-gray-100 below_lg:rounded-lg below_lg:dark:bg-gray-800 below_lg:mb-4">
                    <h1 className="text-3xl font-semibold tracking-wide text-gray-800 dark:text-gray-200 lg:text-4xl lg:mb-4 first-letter:text-primary-default">
                        {oneProjectQuery.data?.title}<span className="text-secondary-default">.</span>
                    </h1>
                </div>

                <div className="lg:flex lg:items-start">
                    <div className="mb-4 lg:mb-0 lg:mr-2 lg:w-1/2 w-full h-96 rounded-lg lg:h-[36rem]">
                        <ProjectCarousel children={oneProjectQuery.data?.images?.map((image, index) => {
                            return <img key={index} className="object-cover object-center w-full h-full rounded-lg" src={getImageUrl(image, "large")} alt="" />
                        }) ?? []} />

                        <ContactCard mode="other" />
                    </div>

                    <div className="mt-4 lg:w-1/2 lg:ml-2 lg:mt-0">

                        {
                            (oneProjectQuery.data?.description || (oneProjectQuery.data?.groups && oneProjectQuery.data.groups.length > 0)) && <div className="p-4 bg-gray-100 rounded-lg dark:bg-gray-800 mb-4">
                                <h2 className="text-2xl font-semibold tracking-wide text-gray-800 dark:text-gray-200 lg:text-3xl m2-4">
                                    {intl.formatMessage(translations.components.projects.descriptionTitle)}
                                </h2>

                                <p className="leading-snug text-gray-800 dark:text-gray-200 mb-4">
                                    {oneProjectQuery.data?.description}
                                </p>

                                {oneProjectQuery.data?.groups && oneProjectQuery.data.groups.length > 0 && <div>
                                    <ProjectGroupTags groups={oneProjectQuery.data?.groups as ProjectGroups[]} />
                                </div>}
                            </div>
                        }

                        {(oneProjectQuery.data?.testimonial !== undefined || oneProjectQuery.data?.partnerFullName !== undefined) && <div className="p-4 bg-gray-100 rounded-lg dark:bg-gray-800">
                            <h2 className="text-2xl font-semibold tracking-wide text-gray-800 dark:text-gray-200 lg:text-3xl mb-2">
                                {intl.formatMessage(translations.components.projects.clientTitle)}
                            </h2>

                            {oneProjectQuery.data?.testimonial !== undefined && oneProjectQuery.data.testimonial!.length > 0 && <p className="leading-snug text-gray-800 dark:text-gray-200 mb-4">
                                “{oneProjectQuery.data?.testimonial}”
                            </p>}

                            <div className="flex items-center">
                                {oneProjectQuery.data?.partnerLogo?.url !== undefined && <img className="object-cover rounded-lg w-14 h-14 mr-4" src={getImageUrl(oneProjectQuery.data?.partnerLogo, "medium")} alt={oneProjectQuery.data.partnerLogo.alternativeText || ""} />}

                                <div>
                                    <h1 className="font-semibold text-primary-default">{oneProjectQuery.data?.partnerFullName}</h1>
                                    <span className="text-sm text-gray-800 dark:text-gray-200">
                                        {oneProjectQuery.data?.partnerRole}{oneProjectQuery.data?.partnerLinkUrl !== undefined && oneProjectQuery.data.partnerLinkUrl!.length > 0 && <> • <a className="text-secondary-default" rel="noreferrer" target="_blank" href={oneProjectQuery.data?.partnerLinkUrl + ""}>{oneProjectQuery.data?.partnerLinkText ?? oneProjectQuery.data.partnerLinkUrl}</a></>} </span>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        </>
    );
};
