import { defineMessages } from "react-intl";

export const projects = defineMessages({
    listTitle: {
        id: "components.projects.list-title",
        defaultMessage: "My projects",
        description: "The main title of the project list"
    },
    listTitleWithFilter: {
        id: "components.projects.list-title-with-filter",
        defaultMessage: "My projects with",
        description: "The main title of the project list with a filter"
    },
    filterBy: {
        id: "components.projects.filter-by",
        defaultMessage: "Filter by service",
        description: "The title of the filter dropdown"
    },
    descriptionTitle: {
        id: "components.projects.description-title",
        defaultMessage: "Description",
        description: "The title of the description section"
    },
    clientTitle: {
        id: "components.projects.client-title",
        defaultMessage: "Client",
        description: "The title of the client section"
    },
    backButton: {
        id: "components.projects.back-button",
        defaultMessage: "Back to projects",
        description: "The text of the back button"
    },
    projectDoesNotExistTitle: {
        id: "components.projects.project-does-not-exist-title",
        defaultMessage: "This project does not exist.",
        description: "The title of the project does not exist page"
    },
    projectDoesNotExistMetaTitle: {
        id: "components.projects.project-does-not-exist-meta-title",
        defaultMessage: "Missing project - Event Technology Aachen Finn Tegeler",
        description: "The meta title of the project does not exist page"
    },
    loadingTitle: {
        id: "components.projects.loading-title",
        defaultMessage: "Loading projects...",
        description: "The title of the loading projects page"
    },
    projectsMetaTitle: {
        id: "components.projects.projects-meta-title",
        defaultMessage: "Project overview - Event Technology Aachen Finn Tegeler",
        description: "The meta title for projects"
    },
    projectsWithMetaTitle: {
        id: "components.projects.projects-with-meta-title",
        defaultMessage: "Projects with {groupTitle} - Event Technology Aachen Finn Tegeler",
        description: "The meta title for projects with a filter"
    },
    projectMetaTitle: {
        id: "components.projects.project-meta-title",
        defaultMessage: "{projectTitle} - Event Technology Aachen Finn Tegeler",
        description: "The meta title for projects"
    }
})