import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import bg from "../../assets/bg.jpg";
import logo from "../../assets/logo.png";
import { Button } from "../../components/Button";
import { ContactCard } from "../../components/ContactCard";
import { Header } from "../../components/Header";
import { HomeServiceCard } from "../../components/HomeServiceCard";
import { Link } from "../../components/Link";
import { ServiceSkeleton } from "../../components/Skeleton/ServiceSkeleton";
import { Text } from "../../components/Text";
import { useImage } from "../../hooks/useImage";
import useQueries from "../../hooks/useQueries";
import { Projects } from "../../models/types";
import translations from "../../translations/constants";

type LocalTestimonial = Pick<Projects, "testimonial" | "partnerFullName" | "partnerLinkUrl" | "partnerLinkText" | "partnerLogo" | "partnerRole" | "projectEndAt" | "slug" | "title">;

export const HomeRoute = () => {
    const intl = useIntl();
    const { getImageUrl } = useImage();

    const { allProjectsQuery, allServicesQuery } = useQueries();

    const testimonials = useMemo(() => {
        if (allProjectsQuery.data && allProjectsQuery.data.length > 0) {
            return allProjectsQuery.data.filter(p => p.testimonial).map<LocalTestimonial>(p => ({
                testimonial: p.testimonial,
                partnerFullName: p.partnerFullName,
                partnerLinkUrl: p.partnerLinkUrl,
                partnerLinkText: p.partnerLinkText,
                partnerLogo: p.partnerLogo,
                partnerRole: p.partnerRole,
                projectEndAt: p.projectEndAt,
                slug: p.slug,
                title: p.title
            }))
        } else {
            return undefined;
        }
    }, [allProjectsQuery.data])

    return (
        <><Helmet>
            <title>{intl.formatMessage(translations.components.home.homeMetaTitle)}</title>
        </Helmet>
            <div className="flex flex-col gap-16 lg:gap-16 lg:pb-16">
                <section className="w-full flex flex-row justify-center relative bg-cover bg-center" style={{ backgroundImage: `url(${bg})` }}>
                    <div className="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-gray-50/80 via-gray-100/75 via-60% to-gray-400/40 dark:from-gray-900/90 dark:via-gray-700/60 dark:via-50% dark:to-gray-400/50" >
                    </div>

                    <div className="container below_lg:mt-16 px-8 py-8 lg:px-6 flex flex-col lg:flex-row gap-4 lg:gap-0 z-10">
                        <div className="w-full min-h-[80vh] flex flex-col justify-center">
                            <div className="flex flex-col below_lg:items-center gap-16">
                                <div className="below_lg:hidden">
                                    <img className="w-auto h-16" src={logo} alt="Finn Tegeler logo" />
                                </div>

                                <h1 className="text-4xl font-semibold tracking-wide text-gray-800 dark:text-white lg:text-6xl">
                                    {intl.formatMessage(translations.components.home.lightingTitle)}<span className="text-primary-default">.<br className="lg:hidden" /></span>{" "}{intl.formatMessage(translations.components.home.soundTitle)}
                                    <span className="text-secondary-default">.</span><br />{" "}{intl.formatMessage(translations.components.home.planningTitle)}
                                    <span className="text-primary-default">.</span><br className="lg:hidden" />{" "}{intl.formatMessage(translations.components.home.consultingTitle)}
                                    <span className="text-secondary-default">.</span>
                                </h1>

                                <div className="flex flex-row below_lg:flex-col w-full justify-center lg:justify-start gap-4">
                                    <Link to="/contact" className="below_lg:w-full">
                                        <Button color="primary" type="default" fullWidth>
                                            {intl.formatMessage(translations.components.home.contactButton)}
                                        </Button>
                                    </Link>
                                    <Link to="/projekt" className="below_lg:w-full">
                                        <Button color="primary" type="outline" fullWidth>
                                            {intl.formatMessage(translations.components.home.projectsButton)}
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="w-full flex flex-col justify-center container p-8 mx-auto grow">
                    <div className="flex flex-col gap-8 lg:gap-16 xl:gap-24">
                        <Header level={1} className="w-full text-center">
                            {intl.formatMessage(translations.components.home.servicesTitle)}<span className="text-secondary-default"></span>
                        </Header>

                        <Text level="larger" className="max-w-2xl mx-auto text-center">
                            {intl.formatMessage(translations.components.home.serviceDescription)}
                        </Text>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 ">
                            {allServicesQuery.isSuccess && allServicesQuery.data.map((service, index) => (<HomeServiceCard key={service.id} index={index} service={service} />))}

                            {!allServicesQuery.isSuccess && <>
                                <ServiceSkeleton type="home" />
                                <ServiceSkeleton type="home" />
                                <ServiceSkeleton type="home" />
                            </>}
                        </div>
                    </div>
                </section>

                {testimonials && testimonials.length > 0 && <section className="bg-white dark:bg-gray-900 lg:py-32">
                    <div className="container mx-auto p-8 flex flex-col gap-8 lg:gap-16 xl:gap-24">
                        <Header level={1} className="w-full text-center">
                            {intl.formatMessage(translations.components.home.testimonialsTitle)}
                        </Header>

                        <Text level="larger" className="max-w-2xl mx-auto text-center">
                            {intl.formatMessage(translations.components.home.testimonialsDescription)}
                        </Text>

                        <section className="grid grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-3">
                            {testimonials.map((testimonial) => (<div key={testimonial.slug} className="p-8 border rounded-lg dark:border-gray-700 flex flex-col gap-8">
                                <Header level={4}>
                                    {testimonial.title}
                                </Header>

                                <Text level="normal">
                                    “{testimonial.testimonial}”.
                                </Text>

                                <div className="flex items-center -mx-2">
                                    {testimonial.partnerLogo?.url !== undefined && <img className="object-cover bg-white rounded-lg w-14 h-14 mr-4" src={getImageUrl(testimonial.partnerLogo, "medium")} alt={testimonial.partnerLogo.alternativeText || ""} />}

                                    <div>
                                        <h1 className="font-semibold text-primary-default">{testimonial.partnerFullName}</h1>
                                        <span className="text-sm text-gray-800 dark:text-gray-200">
                                            {testimonial.partnerRole}{testimonial.partnerLinkUrl !== undefined && testimonial.partnerLinkUrl!.length > 0 && <> • <a className="text-secondary-default" rel="noreferrer" target="_blank" href={testimonial.partnerLinkUrl + ""}>{testimonial.partnerLinkText ?? testimonial.partnerLinkUrl}</a></>}
                                        </span>
                                    </div>
                                </div>
                            </div>))}
                        </section>

                        <div className="w-full flex flex-col items-center">
                            <Link to="/projekt">
                                <Button color="primary" type="outline">
                                    {intl.formatMessage(translations.components.home.testimonialsButton)}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </section>}

                <div className="w-full container mx-auto p-8 flex flex-col justify-center">
                    <div className="flex flex-col gap-16">
                        <Header level={1} className="w-full text-center">
                            {intl.formatMessage(translations.components.contact.contactTitle)}
                        </Header>

                        <ContactCard mode="route" />
                    </div>
                </div>
            </div>
        </>
    );
};
