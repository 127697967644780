import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import strapi from "../../../api";
import { ContactCard } from "../../../components/ContactCard";
import { Header } from "../../../components/Header";
import { ProjectPreview } from "../../../components/ProjectPreview";
import { useLanguage } from "../../../hooks/useLanguage";
import { Projects, Services } from "../../../models/types";
import translations from "../../../translations/constants";

export const ServiceRoute = () => {
    const intl = useIntl();
    const [language] = useLanguage();
    const { slug } = useParams();

    const oneServiceQuery = useQuery({
        queryKey: [language, "service", slug],
        queryFn: async () => {
            const services = await strapi.find<Services[]>("services", {
                slug,
                _locale: language,
            })

            if (services.length !== 1) return undefined;

            return services[0];
        },
        enabled: true,
    });

    const serviceProjectsQuery = useQuery({
        queryKey: [language, "service", slug, "projects"],
        queryFn: async () => {
            return await strapi.find<Projects[]>("projects", {
                'services.id': oneServiceQuery.data?.id,
                _locale: language,
                _limit: 3,
                _sort: "priority:desc",
            });
        },
        enabled: oneServiceQuery.isSuccess,
    });

    const enableProjects = useMemo(() => {
        if (oneServiceQuery.data && oneServiceQuery.data.projects) {
            return oneServiceQuery.data.projects.length > 0;
        }
        return false;
    }, [oneServiceQuery.data]);

    return <div className="flex flex-col gap-8">
        <Helmet>
            <title>{oneServiceQuery.isSuccess ? intl.formatMessage(translations.components.services.serviceMetaTitle, {
                serviceTitle: oneServiceQuery.data?.title
            }) : intl.formatMessage(translations.components.services.serviceDoesNotExistMetaTitle)}</title>
        </Helmet>
        <Link to="/services">
            <button className="mb-4 lg:mb-8 flex items-center justify-center below_lg:w-full  px-5 py-2 text-lg text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="w-6 h-6 rtl:rotate-180">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>

                <span>{intl.formatMessage(translations.components.services.backButton)}</span>
            </button>
        </Link>

        {!oneServiceQuery.isSuccess && <>
            <h1 className="h-2 my-4 bg-gray-300 rounded-lg dark:bg-gray-600" aria-hidden />

            <div className="flex flex-col gap-4 p-4 bg-white rounded-lg dark:bg-gray-800 mb-4">
                <p className="animate-pulse w-48 h-2 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                <p className="animate-pulse w-40 h-2 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                <p className="animate-pulse w-52 h-2 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                <p className="animate-pulse w-64 h-2 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                <p className="animate-pulse w-40 h-2 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
                <p className="animate-pulse w-48 h-2 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
            </div>
        </>}
        {oneServiceQuery.isSuccess &&
            <div className={`${enableProjects ? "" : "flex flex-col mx-auto max-w-screen-xl"}`}>
                <div className="flex flex-col lg:flex-row lg:items-start gap-8 lg:gap-16">
                    <div className={`w-full ${enableProjects ? "lg:w-[50%] " : ""} flex flex-col gap-4`}>
                        <Header level={1} className="below_lg:text-center">
                            {oneServiceQuery.data?.title}</Header>

                        <div className="flex flex-col p-4 bg-white rounded-lg dark:bg-gray-800 mb-4">
                            <p className="tracking-wide leading-relaxed text-gray-600 dark:text-gray-300">{oneServiceQuery.data?.description}</p>
                        </div>

                        <div className="below_lg:hidden flex justify-center">
                            <div className="w-full">
                                <ContactCard mode="other" />
                            </div>
                        </div>
                    </div>

                    {enableProjects && serviceProjectsQuery.isSuccess && serviceProjectsQuery.data.length > 0 && <div className="flex flex-col w-full lg:w-[50%]">
                        <Header level={2} className="below_lg:text-center py-4">
                            {intl.formatMessage(translations.components.services.serviceExampleProjectsTitle)}
                        </Header>
                        <div className="grid grid-cols-1 gap-16">
                            {serviceProjectsQuery.data.map((project) => (<ProjectPreview key={project.id} project={project} mode="service" />))}
                        </div>
                    </div>}


                    <div className="lg:hidden">
                        <ContactCard mode="other" />
                    </div>
                </div>
            </div>
        }
    </div>
} 