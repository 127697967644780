import { defineMessages } from "react-intl";

const generic = defineMessages({
    cancel: {
        id: "components.generic.cancel",
        defaultMessage: "Cancel",
        description: "The text of the cancel button"
    },
    close: {
        id: "components.generic.close",
        defaultMessage: "Close",
        description: "The text of the close button"
    },
})

export default generic;