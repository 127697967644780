import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

export const Footer = () => {
    return (
        <footer className="bg-white dark:bg-gray-900">
            <div className="container px-6 py-2 md:py-4 mx-auto flex items-center justify-between md:flex-row flex-col">
                <Link to="/">
                    <img src={logo} className="h-8 w-auto m-2 cursor-pointer" alt="Finn Tegeler logo" />
                </Link>

                <span className="text-gray-800 dark:text-gray-200">
                    &copy; {new Date().getFullYear()} Finn Tegeler
                </span>
            </div>
        </footer>
    );
};
